import React from "react";
import { Container } from "react-bootstrap";
import "../styles/pages/_about.scss";

const About = () => {
  return (
    <div id="about">
      <Container>
      <div className="about-txt">
      <p>
        Welcome to Leviito, your number one choice for creating your own video
        knowledge base. We're dedicated to giving you the very best of a SaaS
        experience, with a focus on convenience. <br /> <br />
        Founded in 2021, Leviito has a lot to show in this short period of time
        making SaaS platforms better for their users. We hope you enjoy our
        service as much as we enjoy offering it to you. If you have any
        questions or comments, please don't hesitate to contact us. <br />{" "}
        <br />
        Sincerely, <br /> 
        Irfan Sadek <br /> 
        Rezwanul Karim Rifat <br />
        Founders, <b>Leviito</b>.
      </p>
      </div>
      </Container>
    </div>
  );
};

export default About;
