import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

const WhyLeviito = () => {
  return (
    <section id="why-leviito">
      <Container>
        <Row className="row">
          <Col md={6} xs={12}>
            <h1 className="heading">Why LEVIITO ?</h1>
            <p className="sub-text">
              The average users often get stuck. They have two options. Leave
              the site and scour the internet for the right content OR get lost
              in documentations. Levitto comes with the third and best option.{" "}
              <br /> <br /> Never leave the site, access and watch from video
              knowledge base supercharging the user experience.
            </p>
            <Button className="learn-btn">Learn More</Button>
          </Col>
          <Col md={6} xs={12}>
            <div className="box py-4">
              <div>
                <div className="text-center">
                  <img src="/assets/business.png" alt="" />
                  <h2>Happy Customers</h2>
                </div>
                <div className="text-center">
                  <img src="/assets/finance.png" alt="" />
                  <h2>Crazy High Retention Rate</h2>
                </div>
                <div className="text-center">
                  <img src="/assets/e-mail-marketing.png" alt="" />
                  <h2>Very Few Support Tickets</h2>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyLeviito;
