import React from "react";
import { Container, Nav, Button, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header id="header">
      <Navbar expand="lg" variant="dark" className="navigation" collapseOnSelect>
        <Container className="my-4">
          <Nav.Link as={Link} to="/" eventKey="/" style={{ textDecoration: "none" }}>
            <Navbar.Brand className="company-name">
              <img src="/assets/solid.png" alt="logo" /> LEVIITO
            </Navbar.Brand>
          </Nav.Link>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" eventKey="/" className="pages-name mx-3 home-page">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/features" eventKey="/features" className="pages-name mx-3">
                Feature
              </Nav.Link>
              <Nav.Link as={Link} to="/pricing" eventKey="/pricing" className="pages-name mx-3">
                Pricing
              </Nav.Link>
              <Nav.Link as={Link} to="/about" eventKey="/about" className="pages-name mx-3">
                About Us
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" eventKey="/contact" className="pages-name mx-3">
                Contact
              </Nav.Link>

              <Button className="btn-group mx-3 px-4">Sign In</Button>
              <Button variant="outline-light" className="mx-3 px-4">
                Sign Up
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
