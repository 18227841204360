import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <Container className="mx-auto">
          <Row>
            {/* <Col xs={6} md={3} className="footer-links mb-4">
              <h3>Resources</h3>
              <Link className="footer-link" to="/">
                Blog
              </Link>
              <Link className="footer-link" to="/">
                Support
              </Link>
              <Link className="footer-link" to="/">
                Newsletter
              </Link>
            </Col> */}
            {/* <Col xs={6} md={3} className="footer-links mb-4">
              <h3>Community</h3>
              <Link className="footer-link" to="/">
                Twitter
              </Link>
              <Link className="footer-link" to="/">
                Instagram
              </Link>
              <Link className="footer-link" to="/">
                Facebook
              </Link>
              <Link className="footer-link" to="/">
                YouTube
              </Link>
            </Col> */}
            <Col xs={6} md={3} className="footer-links mb-4">
              <h3>Support</h3>
              <Link className="footer-link" to="/">
                My Account
              </Link>
              <a
                className="footer-link"
                target="_blank"
                href="mailto:rifatis1882@gmail.com"
              >
                Help & Support
              </a>
              <a
                className="footer-link"
                target="_blank"
                href="mailto:rifatis1882@gmail.com"
              >
                Contact Us
              </a>
            </Col>
            <Col xs={6} md={3} className="footer-links mb-4">
              <h3>Company</h3>
              <a
                className="footer-link"
                target="_blank"
                href="https://www.privacypolicies.com/live/f492e7dc-537f-4d3f-a63e-f33598c4b849"
              >
                Privacy Policy
              </a>
              <a
                className="footer-link"
                target="_blank"
                href="https://www.termsandconditionsgenerator.com/live.php?token=jdRZ2tcK7EkfAWnlqviE6DTXvxDcIWtx"
              >
                Terms Of Service
              </a>
              {/* <Link className="footer-link" to="/">
                Code Of Conduct
              </Link> */}
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="footer-text d-flex justify-content-center">
        <small>Copyright 2022 © Leviito</small>
      </div>
    </>
  );
};

export default Footer;
