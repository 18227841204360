import React from "react";
import { Container, Button } from "react-bootstrap";

const Subscribe = () => {
  return (
    <section id="subscribe">
      <Container className="subscribe-box">
        <h1 className="heading">
          Subscribe For Easy hacks <br /> & Amazing Deals
        </h1>
        <div className="input-box d-flex justify-content-center mx-auto">
          <input
            type="email"
            placeholder="Input your email address in here"
            className="px-3"
          />
          <div className="btn-subscribe">
            <Button className="rounded mx-2">
              Subscribe Now
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscribe;
