import React from "react";
import { Button, Container } from "react-bootstrap";

const Hero = () => {
  return (
    <div id="hero">
      <Container className="header-second">
        <h1 className="heading">
          NEVER LOSE A CUSTOMER, EVER AGAIN
        </h1>
        <h2 className="heading2">
          BUILD VIDEO KNOWLEDGE BASE THAT YOUR USERS <br /> CAN ACCESS WITHOUT
          LEAVING YOUR SITE{" "}
        </h2>
        <div className="btn-box">
          <Button className="try-free-btn">
            Try For Free
          </Button>
        </div>

        <h3 className="free-trial-text">
          ** GET 2 MONTHS FREE TRIAL , NO CREDIT CARD REQUIRED
        </h3>
      </Container>

      {/* <Container>
        <div className="img-square">
          <img src="/assets/square-empty.png" alt="emptyImg" />
        </div>
      </Container> */}
    </div>
  );
};

export default Hero;
