import React from "react";
import { Button } from "react-bootstrap";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Subscribe from "../components/Subscribe";
import WhyLeviito from "../components/WhyLeviito";
import WorksOnSaas from "../components/WorksOnSaas";
import "../styles/pages/_landing.scss";

const LandingPage = () => {
  return (
    <main>
      <Hero />
      <WorksOnSaas />
      <WhyLeviito />
      <Features />
      {/* <Reviews /> */}
      <section id="price-section">
        <div>
          <h1>A Price To Suit Everyone</h1>
          <p>Unlimited Everything</p>
          <h1>$49 / month</h1>
          <p className="see-more">See, One price. Simple.</p>
          <div className="d-flex justify-content-center">
            <Button className="btn-tryfree px-5">Try For Free</Button>
          </div>
          <h4>** GET 2 MONTHS FREE TRIAL , NO CREDIT CARD REQUIRED</h4>
        </div>
      </section>
      <Subscribe />
    </main>
  );
};

export default LandingPage;
