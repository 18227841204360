import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Features = () => {
  return (
    <section id="features">
      <Container>
        <h1 className="heading">
          What's our <br /> Features
        </h1>
        <Row>
          <Col xs={12} md={4} className="my-2">
            <div className="icon-box p-4 rounded">
              <div className="bg-light w-25 py-4 mt-4 rounded d-flex justify-content-center">
                <img src="/assets/video-icon.png" alt="video-icon" />
              </div>
              <h3 className="mt-5">YouTube or Vimeo Videos</h3>
              <p>
                Users can add Yotube and Vimeo Videos and customise the modal
                player{" "}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="my-2">
            <div className="icon-box p-4 rounded">
              <div className="bg-light w-25 py-3 mt-3 rounded d-flex justify-content-center">
                <img src="/assets/icon.png" alt="video-icon" />
              </div>
              <h3 className="mt-4">Add Multiple Groups</h3>
              <p>
                Users can add mutiple groups with different keys for different
                pages of their site,customers can search and watch with a afew
                clicks
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="my-2">
            <div className="icon-box p-4 rounded">
              <div className="bg-light w-25 py-3 mt-3 rounded d-flex justify-content-center">
                <img src="/assets/icon2.png" alt="video-icon" />
              </div>
              <h3 className="mt-4">Built in Anaytics</h3>
              <p>Built in analytics providing valuable product insights</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
