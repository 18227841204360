import React from "react";
import { Container } from "react-bootstrap";
import Marquee from "react-fast-marquee";


const WorksOnSaas = () => {

  return (
    <section id="works-on-saas">
      <h1 className="text-center mb-5">Works On SAAS BUILT WITH </h1>
      <Container className="mx-auto">
      <Marquee
				speed={20}
				direction="right"
				className="marquee1"
				gradient={false}
			>
				<img className="marquee-logo" src="/assets/html.png" alt="logo" />
				<img className="marquee-logo" src="/assets/css.png" alt="logo" />
				<img className="marquee-logo" src="/assets/js.png" alt="logo" />
				<img className="marquee-logo" src="/assets/react.png" alt="logo" />
				<img className="marquee-logo" src="/assets/next.jpg" alt="logo" />
				<img className="marquee-logo" src="/assets/bootstrap.png" alt="logo" />
			</Marquee>
      </Container>
    </section>
  );
};

export default WorksOnSaas;
